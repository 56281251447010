<template>
    <teleport to="body">
        <div v-if="openDialog" class="dialog-overlay z-40">
            <div class="dialog z-30">
                <div class="flex justify-end items-center">

                    <button
                    @click="closeDialog"
                    class="focus:outline-none bg-light-secondaryLight rounded-full p-1 hover:bg-opacity-50"
                    aria-label="Cerrar"
                    >
                    <svg
                            class="h-6 w-6 stroke-light-primaryDark"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            >
                            <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>

                <div class="flex flex-col justify-center items-center lg:flex-row gap-5">

                    <div class="rounded-lg bg-[#E9E9E9] p-2">
                        <MdiIcon v-if="typeMsg=='warning'" class="text-light-error" icon="mdiAlert" style="width: 23px;height: 23px;" />
                        <MdiIcon v-if="typeMsg=='success'" class="text-light-primaryDark" icon="mdiCheckCircleOutline" style="width: 23px;height: 23px;" />

                    </div>
                    <h3 class="font-semibold text-[20px] leading-[24.2px] text-[#54595E] lg:mt-4"> {{ title }} </h3>
                </div>
                <h4 class="italic text-[#54595E] text-[16px] leading-[19.36px] text-center mt-4 mb-6"> {{ subtitle }} </h4>
                
                <div class="dialog-actions mt-6">
                    <button
                        class="w-48 lg:min-w-80 mx-auto rounded-lg font-semibold leading-[18.75px] text-center px-5 py-3 shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 bg-light-error hover:bg-opacity-95 hover:shadow-lg"
                        :disabled="loadingDialog"
                        @click="$emit('confirm')"
                    >
                        <span v-if="loadingDialog" class="flex items-center justify-center">
                            <TheLoading size="25px" color="#fff"/>
                        </span>
                        <span 
                            v-else
                            style="color:#fff;"
                        >
                        Si, eliminar
                        </span>
                    </button>
                    <button
                        class="w-48 lg:min-w-80 mx-auto rounded-lg font-semibold leading-[18.75px] text-center px-5 py-3 shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 hover:shadow-lg border border-[#4F4F4F]"
                        :disabled="loadingDialog"
                        @click="$emit('closeDialog')"
                    >
                        <span v-if="loadingDialog" class="flex items-center justify-center">
                            <TheLoading size="25px" />
                        </span>
                        <span 
                            v-else
                            class="text-[#4F4F4F] font-medium text-[14px] leading-[16.94px]"
                        >
                        No, cancelar
                        </span>
                    </button>
                
                </div>
            </div>
        </div>
    </teleport>
</template>


<script lang="ts" setup>
type TypeMsg = 'info' | 'success' | 'error' | 'warning';

defineProps<{
    title: string;
    subtitle?: string;
    typeMsg: TypeMsg;
    loadingDialog?: boolean;
}>()

const openDialog = defineModel();
const emit = defineEmits(['closeDialog', 'confirm'])

const closeDialog = () => {
    emit('closeDialog');

}
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: white;
  margin-inline: 20px;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.dialog-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
}
@media (min-width: 1024px) {
  .dialog {
    padding: 20px;
  }
}
</style>
